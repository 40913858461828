@use "../css/variables.scss";
@use "../css/mixins.scss";

.imageContainer {
    margin-top: variables.$spacing-double;
    text-align: center;

    img {
        width: 150px;
        height: auto;

        @include mixins.for-desktop {
            width: 390px;
        }
    }
}

.messageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;

    a {
        color: variables.$text-link-color;
        text-decoration: underline;
    }
}

.secondMessage {
    @include mixins.for-phone {
        margin-top: 0;
    }

    a {
        color: variables.$text-link-color;
        text-decoration: underline;
    }
}

.desktopRight {
    display: flex;
    flex-direction: column;
}