@use "../css/mixins.scss";
@use "../css/variables.scss";

.contentContainer {
    @include mixins.flex-column();
}

.contentMiddle {
    margin-top: variables.$spacing-double;

    @include mixins.not-phone {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.finalContainer {
    @include mixins.flex-column(24px);
    margin: 24px auto;
    align-items: center;
    width: 75%;
}

.didYouKnow {
    color: #000;
}

.weCalculated, .didYouKnow {
    @include mixins.for-phone {
        margin-top: 248px;
    }
    @include mixins.not-phone {
        position: absolute;
        top: 30%;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

.awardText {
    font-size: variables.$font-size-main;
    text-align: center;
}
.awardHigh {
    position: absolute;
    left: 0;
    top: 90px;
    width: 100%;
}
.awardMiddle {
    position: absolute;
    left: 0;
    top: 150px;
    width: 100%;
}

.awardTablet {
    position: absolute;
    left: 0;
    top: 120px;
    width: variables.$tablet-animation-width;
}

.awardDesktop {
    position: absolute;
    left: 0;
    top: 150px;
    width: variables.$desktop-animation-width;
}

.givebackButton {
    @include mixins.not-phone {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.creditsButton {
    @include mixins.not-phone {
        position: absolute;
        bottom: 32px;
        left: 50%;
        transform: translate(-50%, -100%);
    }
}
