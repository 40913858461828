@use "../../../css/mixins.scss";
@use "../../../css/variables.scss";

.bg {
    background-repeat: no-repeat;
    background-size: cover;
}

.questionParent {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @include mixins.for-phone {
        position: absolute;
        
        height: 100%;
        max-height: min(100%, 900px);
    }

    @include mixins.for-tablet {
        width: variables.$tablet-halfpage-width;
    }

    @include mixins.for-desktop {
        width: variables.$desktop-halfpage-width;
        padding-top: variables.$desktop-page-marginy;
    }

    @include mixins.for-small-desktop {
        padding-top: variables.$desktop-min-marginy;
    }
}
