@use "../../../css/variables.scss";
@use "../../../css/mixins.scss";

.summaryContainer {
    @include mixins.flex-column();
    height: 100%;
    overflow: hidden;

    @include mixins.not-phone {
        margin: 0 variables.$desktop-margin;
    }
}

.unfinishedContainer {
    background-color: #fff;
}

.heading {
    text-align: center;
    padding: variables.$spacing-single;
    font-size: variables.$font-size-large;
    font-weight: variables.$font-weight-semibold;
}

.answeredContainer {
    background-color: variables.$background-light-gray;
    padding: variables.$spacing-single;
    margin-bottom: variables.$spacing-double;
}

.answeredQuestion, .unansweredQuestion {
    padding: variables.$spacing-single;
    border-bottom: 2px solid #000;
}

.unansweredQuestion {
    background-color: #fff;
}

.question {
    font-size: variables.$font-size-normal;
    text-align: center;
}

.response {
    font-size: variables.$font-size-more;
    font-weight: variables.$font-weight-medium;
    text-align: center;
}

.buttonContainer {
    padding: variables.$spacing-single;
    margin-bottom: variables.$spacing-double;
    text-align: center;
}
