@use "../css/variables";
@use "../css/mixins";

.loadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.animationContainer {
  width: 384px;
  margin: 0 auto;
}

.imageContainer {
  margin-top: variables.$spacing-double;

  img {
      width: 390px;
      height: auto;

      @include mixins.for-phone {
        width: 160px;
     }

      @include mixins.for-tablet {
          width: 320px;
      }
  }
}
  
.loading {
  width: 100%;
  font-size: variables.$font-size-large;
  margin-top: variables.$spacing-double;
  padding: variables.$spacing-double;
  box-sizing: border-box;
  text-align: center;
}