@use "../css/mixins.scss";
@use "../css/variables.scss";

.logo {
    height: 159px;
    width: auto;
}

.home-container {
    @include mixins.flex-column(variables.$spacing-double);
    padding: 0 variables.$spacing-double;
    text-align: center;

    p {
        width: 340px;
        margin: 0 auto;
    }

    .color-chip {
        width: 16px;
        height: 16px;
    }

    .participation-chip {
        background-color: variables.$color-participation-main;
    }

    .cohesion-chip {
        background-color: variables.$color-cohesion-main;
    }

    .wellbeing-chip {
        background-color: variables.$color-wellbeing-main;
    }

    .perceptions-chip {
        background-color: variables.$color-perceptions-main;
    }

    .demographics-chip {
        background-color: variables.$color-demographics-main;
    }
  
    .survey-container {
        @include mixins.flex-row();
        justify-content: space-around;
        margin: variables.$spacing-double;
    }

    .toc {
        text-align: left;
        margin: 0 auto;
        width: 320px;

        th, td {
            padding: 0 variables.$spacing-single;         
        }

        .section {
            font-weight: variables.$font-weight-medium;
        }

        @include mixins.not-phone {
            width: 400px;
        }
    }

    .button-container {
        margin-top: 32px;
        padding: variables.$spacing-double;
    }

    @include mixins.devices(tablet) {
        padding: 0 variables.$spacing-single;
        p {
            width: 400px;
        }
        .button-container {
            margin-top: variables.$spacing-double;
            padding: variables.$spacing-double;
        }
    }
}
