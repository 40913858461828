@use "../css/mixins.scss";
@use "../css/variables.scss";

.drawing-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: variables.$spacing-double;
    min-height: 0;
    min-width: 0;
    
    @include mixins.for-phone {
        width: 100%;
        height: 844px;
    }
    @include mixins.for-tablet {
        width: variables.$tablet-halfpage-width;
        height: variables.$tablet-animation-height;
    }
    @include mixins.for-desktop {
        padding: 96px;
        width: variables.$desktop-halfpage-width;
        height:  variables.$desktop-animation-height;
    }

    .drawing-instructions {
        text-align: center;
        margin: variables.$spacing-double;
        max-width: 240px;

        @include mixins.not-phone {
            margin-top: 40px;
            font-size: variables.$font-size-normal;
        }
        @include mixins.for-desktop {
            margin-top: 24px;
            padding-left: 32px;
        }
        @include mixins.for-small-desktop {
            margin-top: 0;
        }
    }

    .drawing-footnote {
        margin-top: -280px;
        text-align: center;

        @include mixins.for-tablet {
            margin-top: -220px;
        }
        @include mixins.for-desktop {
            margin-top: -320px;
        }
        @include mixins.for-small-desktop {
            margin-top: -360px;
        }
        @include mixins.not-phone {
            font-size: variables.$font-size-normal;
        }
    }

    .drawing-animation-container {
        position: absolute;
        display: block;
        flex: 1 1 100%;
        top: 0;
        min-width: 0;
        width: 100%;
        min-height: 0;
        height: 844px;
        margin: 0 auto;
        background-repeat: no-repeat;

        @include mixins.for-phone {
            left: max(0, calc(100% - 384px));
            max-width: 384px;
        }

        @include mixins.for-tablet {
            left: 0;
            max-width: variables.$tablet-animation-width;
        }
        @include mixins.for-desktop {
            left: variables.$desktop-page-marginx;
            max-width: variables.$desktop-animation-width;
        }

    }

    .canvas-container {
        width: 100%;
        height: 100%;

        canvas {
            position: absolute;
            // border: 1px solid #999;
        }

        @include mixins.for-tablet {
            height: variables.$tablet-animation-height;
        }
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes fadeOut {
        0% { opacity: 1; }
        50% { opacity: 1; }
        100% { opacity: 0; }
    }

    .example {
        opacity: 0;
        font-size: variables.$font-size-main;
        font-weight: variables.$font-weight-bold;
        text-align: center;
        padding: variables.$spacing-double 0;
        position: absolute;
        animation: fadeOut 5s;
        pointer-events: none;
    }
}

.draw-lottie-container {
    position: absolute;
    top: 0;
    left: calc((100% - 384px) / 2);
    min-width: 0;
    min-height: 0;
    max-width: 100vw;
  
    @include mixins.for-phone {
        left: calc((100% - variables.$phone-animation-width) / 2);
        width: variables.$phone-animation-width;
        height: 844px;

        .draw-lottie {
            min-width: 0;
            height: 844px;
            max-width: 100vw;
            width: variables.$phone-animation-width;
        }

        svg {
            width: variables.$phone-animation-width;
            height: 844px;
        }    
    }

    @include mixins.for-tablet {
        left: variables.$tablet-page-marginx;
        top: variables.$tablet-page-marginy;
        width: variables.$tablet-animation-width;
        height: variables.$tablet-animation-height;
    }

    @include mixins.for-desktop {
        left: variables.$desktop-page-marginx;
        top: variables.$desktop-page-marginy;
        width: variables.$desktop-animation-width;
        height: variables.$desktop-animation-height;

        .draw-lottie {
            width: variables.$desktop-animation-width;
            height: variables.$desktop-animation-height;
        }

        svg {
            width: variables.$desktop-animation-width;
            height: variables.$desktop-animation-height;
        }    
    }

    @include mixins.for-small-desktop {
        top: variables.$desktop-min-marginy;
    }
  }

  .drawing-human {
    display: block;
    text-align: center;

    @include mixins.for-phone {
        margin: 40px variables.$spacing-double variables.$spacing-double;
    }

    @include mixins.for-tablet {
        margin: 40px auto;
    }

    @include mixins.for-desktop {
        margin: 96px auto;
    }
  }

  .drawing-intro {
    display: block;
    text-align: center;
    width: 100%;
    margin: variables.$spacing-double 0;
    
    @include mixins.not-phone {
        margin: 40px auto;
        width: 80%;
    }

    @include mixins.for-desktop {
        margin: 96px auto;
        width: 80%;
    }
}
