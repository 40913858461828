@use "../../../css/variables";
@use "../../../css/mixins";

.participation {
    font-size: 20px;
    white-space: pre-line;
    box-sizing: border-box;

  }

.intro {
  p {
    margin: 0;
  }
}

.bg {
    z-index: 0;
    pointer-events: none;
}
