@use "../../../css/mixins.scss";
@use "../../../css/variables.scss";

.bg {
    background-image: url('/images/S0_zipcode_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.questions {
    padding: variables.$spacing-single variables.$spacing-double;
    text-align: center;
    border-radius: 32px;
    margin: 0 auto;
    background-color: variables.$color-intro-background;
}

.zipInput {
    position: absolute;
    left: 10%;
    top: 50%;
    opacity: 0;
    width: 1px;
    height: 1px;
    z-index: -1;
}

.digit {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    border: none;
    margin: variables.$spacing-single;
    line-height: 100%;
    font-weight: variables.$font-weight-semibold;
}
