@use "../../../css/variables.scss";
@use "../../../css/mixins.scss";

.header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 50;
    padding-top: variables.$spacing-single;
}

.title {
    font-size: variables.$font-size-title;
    font-weight: variables.$font-weight-bold;
    text-align: center;
    text-transform: uppercase;
}

.buttonContainer {
    flex: 0 0 41px;
    height: 100%;
}

.counterContainer {
    position: relative;
}

.textInfo {
    @include mixins.flex-column(variables.$spacing-half);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: variables.$spacing-single;
    align-items: center;
    color: #fff;
    box-sizing: border-box;
}