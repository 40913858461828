@use "../css/variables";
@use "../css/mixins";

.container {
    @include mixins.flex-row(variables.$spacing-single);
    box-sizing: border-box;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 36px;
    
    button {
        border-color: transparent;
        background-color: transparent;
    }

    @include mixins.for-desktop {
        position: absolute;
        top: calc(100% - 50%);
        left: 50%;
        width: variables.$desktop-halfpage-width;
        transform: translateX(-50%);
    }
}

