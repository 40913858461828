@use "../css/variables.scss";

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 366px;
    padding: variables.$spacing-half;
    margin: 0 auto;
}

.block {
    width: 50px;
    height: 5px;
    box-sizing: border-box;
    z-index: 100;
}

.activeBlock {
    width: 50px;
    height: 15px;
    box-sizing: border-box;
    z-index: 100;
}


.connector {
    position: absolute;
    top: calc(100% - 50% - 0.5px);
    width: 360px;
    height: 1px;
    background-color: #000;
}

.participation {
    background-color: variables.$color-participation-main;
}

.cohesion {
    background-color: variables.$color-cohesion-main;
}

.wellbeing {
    background-color: variables.$color-wellbeing-main;
}

.perceptions {
    background-color: variables.$color-perceptions-main;
}

.demographics {
    background-color: variables.$color-demographics-main;
}
