@use "../css/mixins.scss";
@use "../css/variables.scss";

.header {
    @include mixins.for-phone {
      position: sticky;
      top: 0;
      min-height: 115px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 50;
  }
  