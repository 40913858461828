@use "./variables.scss";
@use "./mixins.scss";

body {
  font-family: 'Dosis', sans-serif;
  font-size: variables.$font-size-normal;
  background-color: #fff;

  @include mixins.for-phone {
    font-size: variables.$font-size-normal;
  }
  @include mixins.not-phone {
    font-size: variables.$font-size-desktop;
  }
}

h2 {
  margin: variables.$spacing-single 0;
}

a {
  color: #1f363e;
  text-decoration: none;
}

p, ul {
  margin: variables.$spacing-single 0;
  
  @include mixins.not-phone {
    margin: variables.$spacing-double 0;
  }
}

.phone-only {
  @include mixins.not-phone {
    display: none !important;
  }
}

.not-phone {
  @include mixins.for-phone {
    display: none;
  }
}

.desktop-only {
  @include mixins.for-phone {
    display: none;
  }
  @include mixins.for-tablet {
    display: none;
  }
}

.left-panel, .content-panel {
  position: relative;

  @include mixins.for-phone {
    height: 100vh;
  }

  @include mixins.for-tablet {
    width: variables.$tablet-halfpage-width;
    height: variables.$tablet-page-height;
  }
  @include mixins.for-desktop {
    width: variables.$desktop-halfpage-width;
    height: variables.$desktop-page-height;
  }
  @include mixins.for-small-desktop {
    height: variables.$desktop-min-height;
  }
}

.right-panel {
  box-sizing: border-box;
  width: 50%;

  @include mixins.for-tablet {
    padding: variables.$tablet-margin;
  }
}

.padded-panel {
  @include mixins.for-desktop {
    box-sizing: border-box;
    padding: variables.$desktop-page-marginy variables.$desktop-page-marginx;
  }

  @include mixins.for-small-desktop {
    box-sizing: border-box;
    padding: variables.$desktop-min-marginy variables.$desktop-page-marginx;
  }
}

.background-container {
  @include mixins.for-phone {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  @include mixins.for-tablet {
    width: variables.$tablet-animation-width;
  }
  @include mixins.for-desktop {
    width: variables.$desktop-halfpage-width;
    height: variables.$desktop-animation-height;
    box-sizing: border-box;
    padding: variables.$desktop-page-marginy variables.$desktop-page-marginx;
  }
  @include mixins.for-small-desktop {
    padding: variables.$desktop-min-marginy variables.$desktop-page-marginx;
  }
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.main-container {
  padding: variables.$spacing-double;
}

.main-title {
  font-size: variables.$font-size-title;
  font-weight: variables.$font-weight-bold;
  color: variables.$main-dark;
}

.main-subtitle {
  font-size: variables.$font-size-subtitle;
  line-height: variables.$font-size-subtitle;
  font-weight: variables.$font-weight-semibold;
  color: variables.$main-dark;
}

.screen-title {
  font-size: variables.$font-size-large;
  font-weight: variables.$font-weight-medium;
  color: variables.$primary-text-dark;
  text-align: center;
  margin: variables.$spacing-single;
}

.section-title {
  font-size: variables.$font-size-title;
  font-weight: variables.$font-weight-bold;
  text-align: center;
  text-transform: uppercase;
}

.main-text {
  font-size: variables.$font-size-main;
  padding: variables.$spacing-single;
  margin-bottom: variables.$spacing-double;
  text-align: center;
  white-space: pre-line;
}

.question-large {
  margin: variables.$spacing-single 0;
  font-size: variables.$font-size-large;
  font-weight: variables.$font-weight-medium;
  text-align: center;
}

.question {
  font-size: variables.$font-size-large;
  padding: variables.$spacing-single;
  text-align: center;
  white-space: pre-line;
}

.unanswered-question {
  font-size: 22px;
  padding: variables.$spacing-single;
  text-align: center;
  white-space: pre-line;
}

.valign-bottom {
  .question {
    margin-top: variables.$spacing-single;
    margin-bottom: 0;
  }
}

.static-text {
  font-size: variables.$font-size-main;
  font-weight: variables.$font-weight-medium;
  padding: variables.$spacing-single;
  white-space: pre-line;
}

.static-text-small {
  font-size: 20px;
  font-weight: variables.$font-weight-medium;
  padding: variables.$spacing-single;
  white-space: pre-line;
}

.secondary-text, .footnotes {
  font-size: variables.$font-size-normal;
  font-weight: variables.$font-weight-medium;
  text-align: center;
}

.message-text {
  font-size: variables.$font-size-medium;
  margin: 48px 0;
  text-align: center;
}

.completion-text {
  text-align: center;
  color: #000;
}

.artform-name {
  font-size: variables.$font-size-medium;
  font-weight: variables.$font-weight-semibold;
  text-align: center;
}

.framed-container {
  @include mixins.for-tablet {
    width: variables.$tablet-halfpage-width;
    height: variables.$tablet-page-height;
  }
  @include mixins.for-desktop {
      width: variables.$desktop-animation-width;
      height: variables.$desktop-animation-height;
      margin: variables.$desktop-page-marginy 0;
      margin-left: variables.$desktop-page-marginx;
  }
  @include mixins.for-small-desktop {
    margin-top: variables.$desktop-min-marginy;
  }

  @include mixins.not-phone {
      background-image: url('/images/S0-frame.png');
      background-repeat: no-repeat;
      background-size: cover;
      box-sizing: border-box;
      padding: 36px;
  }
}

.frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;

  @include mixins.for-tablet {
    width: variables.$tablet-animation-width;
    height: variables.$tablet-animation-height;
  }
  @include mixins.for-desktop {
    width: variables.$desktop-animation-width;
    height: variables.$desktop-animation-height;
  }

  @include mixins.not-phone {
      background-image: url('/images/S0-frame.png');
      background-repeat: no-repeat;
      background-size: cover;
  }
}

.question-container, .unanswered-question-container {
  @include mixins.flex-column(variables.$spacing-single);
  
  justify-content: center;
  width: calc(100% - 2 * variables.$spacing-double);
  max-height: 100%;
  max-width: 372px;
  margin: variables.$spacing-single auto;
  padding: variables.$spacing-single;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.choice-column {
  @include mixins.flex-column(variables.$spacing-double);
}

.content-relative {
  position: relative;
}

.full-height {
  height: 100%;
}

.centered {
  text-align: center;
}

.right-aligned {
  text-align: right;
}

.scroll-y {
  overflow-y: auto;
}

.fit-y {
  overflow-y: hidden;
}

.pre-line {
  white-space: pre-line;
}

.error {
  color: variables.$error-color;
}

.hidden {
  visibility: hidden;
}

.text-container {
  font-size: variables.$font-size-normal;
}

.container {
  padding: variables.$spacing-double;
}

.relative-container {
  position: relative;
}

.clickable {
  cursor: pointer;
}

.self-describe-container {
  margin: 0 variables.$spacing-double;
}

.details {
  font-size: variables.$font-size-normal;
}

.padding-double {
  padding: variables.$spacing-double;
}

.margin-bottom {
  height: 60px;
  flex-shrink: 0;

  @include mixins.not-phone {
    height: variables.$spacing-single;
  }
}

.margin-right-2 {
  margin-right: variables.$spacing-double;
}

.flex-row-center {
  @include mixins.flex-row();
  justify-content: center;
}

.flex-row-align-start {
  @include mixins.flex-row();
  align-items: flex-start;
  justify-content: center;
}

.flex-row-align-center {
  @include mixins.flex-row();
  justify-content: space-evenly;
  align-items: center;
}

.flex-row-wrap {
  @include mixins.flex-row();
  justify-content: center;
  flex-wrap: wrap;
  gap: variables.$spacing-single;
}

.flex-column {
  @include mixins.flex-column(variables.$spacing-double);
}

.flex-column-single-space {
  @include mixins.flex-column(variables.$spacing-single);
}

.flex-column-no-gap {
  @include mixins.flex-column(0);
}

.flex-column-center {
  @include mixins.flex-column(variables.$spacing-double);
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-fit {
  flex-grow: 1;
  overflow: hidden;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-no-gap {
  gap: 0;
}

.z-front {
  position: relative;
  z-index: 100;
}

.z-middle {
  position: relative;
  z-index: 20;
}

.valign-middle {
  @include mixins.for-phone {
    position: absolute;
    top: 50%; 
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
}

.valign-bottom {
  @include mixins.for-phone {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 72px;
  }
}

.choice-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: variables.$spacing-single;
}

button {
  appearance: none;
  background: none;
  outline: none;
  font-family: 'Dosis', sans-serif;
  cursor: pointer;
}

.onop-button {
  font-size: variables.$font-size-button;
  font-weight: variables.$font-weight-button;
  line-height: variables.$font-size-button;
  padding: variables.$spacing-single variables.$spacing-double;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  color: variables.$primary-text-dark;
  background-color: #fff;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}

.onop-button:hover,
.onop-button:focus {
  background-color: variables.$hover-color;
}

.onop-button:active {
  background-color: rgba(112, 76, 182, 0.2);
  color: #fff;
}

.onop-button-smallprint {
  font-size: variables.$button-label-small;
  line-height: variables.$font-size-small-button;
}

.onop-button-primary {
  text-transform: uppercase;
}

.wide-button {
  width: 100%;
}

.anchor-button {
  display: inline-block;
}

.onop-button-smalltwoline {
  font-size: variables.$button-label-small;
  line-height: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.icon-button {
  @include mixins.flex-row(variables.$spacing-single);
  align-items: center;
}

.nav-button {
  font-size: variables.$font-size-button;
  box-sizing: border-box;
  color: variables.$primary-text-dark;
  text-align: center;
  transition: all 0.15s;
  padding: 0 variables.$spacing-single;
  border-color: transparent;
  background-color: transparent;
}

.nav-button:hover {
  svg {
      color: #888;
  }  
}

.nav-button:disabled {
  svg {
    color: #333;
  } 
  opacity: 0.25;
}

.system-button {
  cursor: pointer;
  font-size: 12px;
  padding: variables.$spacing-double;
}

.secondary-button {
  border-radius: 4px;
  border: none;
  padding: variables.$spacing-half variables.$spacing-single;
  font-size: variables.$font-size-more;
  font-weight: variables.$font-weight-semibold;
}

.nav-label {
  font-size: variables.$font-size-normal;
  font-weight: variables.$font-weight-medium;
  text-align: center;
}

.text-input {
  padding: variables.$spacing-half variables.$spacing-single;
  font-size: variables.$font-size-input;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  max-width: 100%;
}

.text-input-3 {
  width: 80px;
}

/* hide arrow buttons in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.action-buttons {
  margin-top: variables.$spacing-single;

  div {
      margin: variables.$spacing-single;
      text-align: center;
  }

  .onop-button {
      display: inline-block;
      width: 100px;
      text-transform: uppercase;
  }

  .wide-button {
    width: 300px;
  }
}

.lottie-container {
  position: relative;
  min-width: 0;
  min-height: 0;
  max-width: 100vw;
  pointer-events: none;
  
  @include mixins.for-phone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: variables.$phone-animation-height;
  }
  @include mixins.for-tablet {
    width: variables.$tablet-animation-width;
    height: variables.$tablet-animation-height;
  }
  @include mixins.for-desktop {
    width: variables.$desktop-animation-width;
    height: variables.$desktop-animation-height;
  }

  .lottie {
      min-width: 0;
      max-width: 100vw;
      width: auto;
  }

  svg {
      width: 384px;
      height: variables.$phone-animation-height;
  }
}

.validated-check {
  font-size: 60px;
  color: #000;
  font-weight: variables.$font-weight-bold;
  text-align: center;
  padding: variables.$spacing-single;

  svg {
    color: #000;
  } 
}

.zip-check {
  @include mixins.for-phone {
    margin-top: -42px;
  }

  svg {
    color: variables.$main-dark;
  } 
}

.slider-container {
  width: 90%;
}

.scroll-arrow {
  position: absolute;
  right: 0;
  top: 80px;
  width: 15px;
  height: 100px;

  @include mixins.for-tablet {
    top: 150px;
  }

  @include mixins.for-desktop {
    top: 200px;
  }
  
  svg {
    color: variables.$main-dark;
  }
}

@keyframes delayReveal {
  0% { opacity: 0; max-height: 0}
  80% { opacity: 0; max-height: 0}
  100% { opacity: 1; max-height: 1000px; }
}

.delay-reveal {
  opacity: 1;
  max-height: 1000px;
  animation: delayReveal 2.5s;
  overflow: hidden;
}

// section-dependent styles

.layout-intro {
  @include mixins.not-phone {
    background-color: variables.$color-intro-background;
  }
}

.layout-splash {
  @include mixins.not-phone {
    background-color: inherit;
  }
}

.section-intro {
  .onop-button {
    background-color: variables.$color-intro-20;
  }

  .onop-button:hover, .onop-button:focus {
    background-color: variables.$color-intro-30;
  }

  .question-container {
    @include mixins.for-phone {
      background-color: variables.$color-intro-background;
    }
  }

  .text-input {
    background-color: variables.$color-intro-20;
  }

  .selected, .current {
    background-color: variables.$color-intro-main;
  }
}

.layout-participation {
  @include mixins.not-phone {
    background-color: variables.$color-participation-background;
  }
}

.layout-participation.layout-toc:not(.layout-overview) {
  @include mixins.not-phone {
    background-image: url('/images/S1_border.svg');
  }
}

.section-participation {
  .question-header {
    .title {
      color: variables.$color-participation-dark;
    }
  }

  .section-title {
    color: variables.$color-participation-dark;
  }

  .question-container {
    @include mixins.for-phone {
      background-color: variables.$color-participation-background;
    }
  }

  .unanswered-question-container {
    background-color: variables.$color-participation-background;
  }

  .text-input {
    border-color: variables.$color-participation-dark;
    background-color: variables.$color-participation-20;
  }

  .bottom-border-dark {
    border-bottom: 2px solid variables.$color-participation-dark;
  }

  .nav-button:hover {
    svg {
        color: variables.$color-participation-main;
    }  
  }

  .onop-button {
    background-color: variables.$color-participation-20;
  }

  .onop-button:hover,
  .onop-button:focus {
    background-color: variables.$color-participation-light;
  }

  .scroll-arrow {
    svg {
      color: variables.$color-participation-dark;
    }
  }

  .completed {
    background-color: variables.$color-participation-dark;
  }

  .selected, .current, .slider-thumb {
    background-color: variables.$color-participation-main;
  }

  .unanswered {
    background-color: variables.$color-participation-10;
  }

  .selected:hover {
    background-color: variables.$color-participation-main;
  }
}

.layout-cohesion {
  @include mixins.not-phone {
    background-color: variables.$color-cohesion-background
  }
}
.layout-cohesion.layout-toc:not(.layout-overview) {
  @include mixins.not-phone {
    background-image: url('/images/S2_border.svg');
  }
}

.section-cohesion {
  .question-header {
    .title {
      color: variables.$color-cohesion-dark;
    }
  }

  .section-title {
    color: variables.$color-cohesion-dark;
  }

  .question-container {
    @include mixins.for-phone {
      background-color: variables.$color-cohesion-background;
    }
  }

  .unanswered-question-container {
    background-color: variables.$color-cohesion-background;
  }

  .text-input {
    border-color: variables.$color-cohesion-dark;
  }

  .bottom-border-dark {
    border-bottom: 2px solid variables.$color-cohesion-dark;
  }

  .onop-button {
    background-color: variables.$color-cohesion-20;
  }

  .onop-button:hover {
    background-color: variables.$color-cohesion-mid;
  }

  .scroll-arrow {
    svg {
      color: variables.$color-cohesion-dark;
    }
  }

  .completed {
    background-color: variables.$color-cohesion-dark;
  }

  .selected, .current {
    background-color: variables.$color-cohesion-main;
  }

  .unanswered {
    background-color: variables.$color-cohesion-10;
  }

  .nav-button:hover {
    svg {
        color: variables.$color-cohesion-main;
    }  
  }

  .selected:hover {
    background-color: variables.$color-cohesion-main;
  }
}

.layout-wellbeing {
  @include mixins.not-phone {
    background-color: variables.$color-wellbeing-background
  }
}
.layout-wellbeing.layout-toc:not(.layout-overview) {
  @include mixins.not-phone {
    background-image: url('/images/S3_border.svg');
  }
}

.section-wellbeing {
  .question-header {
    .title {
      color: variables.$color-wellbeing-dark;
    }
  }

  .section-title {
    color: variables.$color-wellbeing-dark;
  }

  .question-container {
    @include mixins.for-phone {
      background-color: variables.$color-wellbeing-background;
    }
  }

  .unanswered-question-container {
    background-color: variables.$color-wellbeing-background;
  }

  .text-input {
    border-color: variables.$color-wellbeing-dark;
  }

  .bottom-border-dark {
    border-bottom: 2px solid variables.$color-wellbeing-dark;
  }

  .onop-button {
    background-color: variables.$color-wellbeing-20;
  }

  .onop-button:hover {
    background-color: variables.$color-wellbeing-mid;
  }

  .completed {
    background-color: variables.$color-wellbeing-dark;
  }

  .selected, .current {
    background-color: variables.$color-wellbeing-main;
  }

  .unanswered {
    background-color: variables.$color-wellbeing-10;
  }

  .nav-button:hover {
    svg {
        color: variables.$color-wellbeing-main;
    }  
  }

  .selected:hover {
    background-color: variables.$color-wellbeing-main;
  }
}

.layout-perceptions {
  @include mixins.not-phone {
    background-color: variables.$color-perceptions-background;
  }
}
.layout-perceptions.layout-toc:not(.layout-overview) {
  @include mixins.not-phone {
    background-image: url('/images/S4_border.svg');
  }
}

.section-perceptions {
  .question-header {
    .title {
      color: variables.$color-perceptions-dark;
    }
  }

  .section-title {
    color: variables.$color-perceptions-dark;
  }

  .question-container {
    @include mixins.for-phone {
      background-color: variables.$color-perceptions-background;
    }
  }

  .unanswered-question-container {
    background-color: variables.$color-perceptions-background;
  }

  .text-input {
    background-color: variables.$color-perceptions-20;
  }

  .bottom-border-dark {
    border-bottom: 2px solid variables.$color-perceptions-dark;
  }

  .completed {
    background-color: variables.$color-perceptions-dark;
  }

  .onop-button {
    background-color: variables.$color-perceptions-20;
  }

  .onop-button:hover {
    background-color: variables.$color-perceptions-mid;
  }

  .selected, .current {
    background-color: variables.$color-perceptions-main;
  }

  .unanswered {
    background-color: variables.$color-perceptions-10;
  }

  .nav-button:hover {
    svg {
        color: variables.$color-perceptions-main;
    }  
  }

  .selected:hover {
    background-color: variables.$color-perceptions-main;
  }
}

.toc-survey {
  .section-cohesion {
    .choice-column {
      min-width: 240px;
    }
  }

  .section-wellbeing {
    .choice-column {
      min-width: 300px;
    }
  }

  .section-perceptions {
    .choice-column {
      min-width: 300px;
    }
  }
}

.layout-demographics {
  @include mixins.not-phone {
    background-color: variables.$color-demographics-background;
  }
}

.layout-demographics.layout-toc:not(.layout-overview) {
  @include mixins.not-phone {
    background-image: url('/images/S5_border.svg');
  }
}

.section-demographics {
  .question-header {
    .title {
      color: variables.$color-demographics-dark;
    }
  }

  .section-title {
    color: variables.$color-demographics-dark;
  }

  .question-container {
    @include mixins.for-phone {
      background-color: variables.$color-demographics-background;
    }
  }

  .text-input {
    background-color: variables.$color-demographics-20;
  }

  .completed {
    background-color: variables.$color-demographics-dark;
  }

  .onop-button {
    background-color: variables.$color-demographics-20;
  }

  .onop-button:hover {
    background-color: variables.$color-demographics-mid;
  }

  .scroll-arrow {
    svg {
      color: variables.$color-demographics-dark;
    }
  }

  .selected, .current {
    background-color: variables.$color-demographics-main;
  }

  .unanswered {
    background-color: variables.$color-demographics-10;
  }

  .nav-button:hover {
    svg {
        color: variables.$color-demographics-main;
    }  
  }

  .selected:hover {
    background-color: variables.$color-demographics-main;
  }

  .choice-column {
    min-width: 200px;

    @include mixins.not-phone {
      margin-right: 20px;
    }
  }
}

.layout-giveback {
  @include mixins.not-phone {
    background-color: variables.$color-intro-background;
  }
}

.section-giveback {
  .onop-button {
    background-color: variables.$highlight-color-20;
  }

  .secondary-button {
    background-color: variables.$color-participation-20;
  }

  .onop-button:hover {
    background-color: variables.$highlight-color-hover;
  }

  .question-container {
    background-color: variables.$color-giveback-background;
    color: #fff;
  }

  @include mixins.not-phone {
    .question-container {
      background-color: variables.$color-giveback-background-desktop;
    }

    .secondary-button {
      background-color: variables.$color-intro-20;
    }
  }
}

.section-ap-giveback {
  .onop-button {
    background-color: variables.$highlight-color-20;
    text-transform: uppercase;
  }

  .onop-button:hover {
    background-color: variables.$highlight-color-hover;
  }

  .secondary-button {
    background-color: variables.$color-intro-20;
  }

  .additional-button {
    background-color: variables.$color-perceptions-20;
  }

  .question-container {
    background-color: variables.$color-intro-background;

    @include mixins.not-phone {
      background-color: variables.$color-giveback-background-desktop;
      color: #fff;
    }
  }

  .question-container.step-0 {
    @include mixins.not-phone {
      background-color: transparent;
      color: #000;
    }
  }

  .question-container.step-final {
    @include mixins.not-phone {
      background-color: variables.$color-intro-background;
      color: #000;
      margin-top: -136px;
    }

    @include mixins.for-tablet {
      margin-top: -100px;
    }

    @include mixins.for-phone {
      margin-top: -120px;
    }
  }

  .question-container.step-final.second-question {
    @include mixins.not-phone {
      margin-top: 40px;
    }
    @include mixins.for-phone {
      margin-top: 40px;
    }
  }

  .question-container.highlighted {
    background-color: variables.$highlight-bg-20;

    @include mixins.not-phone {
      background-color: variables.$color-perceptions-20;
      color: #000;
    }
  }
}

// survey-specific styles

.ap-survey {
  .section-intro,
   .section-participation {
    .onop-button {
      text-transform: uppercase;
    }
  }

  .section-cohesion, .section-wellbeing {
    .choice-column {
      min-width: 200px;
    }
  }
}

// language-specific styles
.language-es {
  .main-subtitle {
    font-size: variables.$font-size-large;
  }

  .ap-survey {
    .main-subtitle {
      font-size: 20px;
      text-align: center;
    }
  }

  .nav-label {
    font-size: 14px;
  }
}
