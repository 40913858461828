@use "../css/mixins";
@use "../css/variables";

.afebLogoContainer {
    position: absolute;
    top: 72px;
    left: 53px;
    width: 540px;
    text-align: center;

    @include mixins.for-tablet {
        width: variables.$tablet-halfpage-width;
        left: 0;
        top: 24px;
    }
}

.afebLogo {
    width: auto;

    @include mixins.for-phone {
        margin-top: 20px;
        height: 161px;
    }

    @include mixins.not-phone {
        background-color: variables.$color-intro-light;
        opacity: 0.85;
        border-radius: 20px;
        height: 375px;
        margin: 0 auto;
    }

    @include mixins.for-tablet {
        height: 320px;
    }

    @include mixins.for-desktop {
        height: 375px;
    }
}

.onopLogo {
    height: 159px;
    width: auto;
}

.firstMessage {
    font-weight: variables.$font-weight-medium;
    padding: variables.$spacing-single 36px;

    @include mixins.for-phone {
        background-color: variables.$color-intro-light;
    }

    @include mixins.for-desktop {
        margin-top: 30px;
        font-size: variables.$font-size-more;
    }
}

.secondMessage {
    display: block;
    font-weight: variables.$font-weight-medium;
    margin-bottom: 20px;

    a {
        font-weight: variables.$font-weight-bold;
    }

    @include mixins.for-phone {
        margin-top: 48px;
        background-color: variables.$color-cohesion-light;

        a {
            text-decoration: underline;
        }
    }

    @include mixins.not-phone {
        margin-top: 72px;
        background-color: variables.$color-cohesion-20;
        font-size: variables.$font-size-more;
    }

    @include mixins.for-tablet {
        margin-top: 36px;
    }
}

.continueButton {
    background-color: variables.$color-cohesion-20;
    text-decoration: underline;
    text-transform: uppercase;
}

.linkContainer {
    text-align: center;

    @include mixins.for-phone {
        position: absolute;
        top: calc(100% - 80px);
        width: 100%;
    }

    @include mixins.not-phone {
        width: calc(100% - 32px);
        max-width: 372px;
        margin: 256px auto 0;
    }

    @include mixins.for-tablet {
        margin-top: 180px;
    }
}

.linkButton {
    background-color: variables.$color-intro-20;
    text-transform: uppercase;
}

.version {
    font-size: variables.$font-size-small;
    color: #36c;
    margin: variables.$spacing-single;
    text-align: center;

    @include mixins.not-phone {
        color: rgb(91, 119, 175);
    }
}