@use "../css/mixins.scss";
@use "../css/variables.scss";

.contentContainer {
    display: flex;
    flex-direction: column;
}

.contentMiddle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.secondaryText {
    line-height: normal;
    text-align: center;
}

.finalContainer {
    @include mixins.flex-column(40px);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    align-items: center;
    transform: translateX(-50%);

    @include mixins.for-tablet {
        margin-top: -28px;
        width: calc(100% - 32px);
        gap: 24px;
    }

    @include mixins.for-phone {
        margin-top: -48px;
        width: calc(100% - 32px);
    }
}

.secondButtonContainer {
    margin-top: -60px;

    @include mixins.for-tablet {
        margin-top: -44px;
    }
}

.creditsButton {
    @include mixins.for-phone {
        margin-top: calc(-560px);
    }
}

.didYouKnow {
    color: #000;

    @include mixins.for-phone {
        margin: 32px variables.$spacing-single 0;
        background-color: variables.$color-intro-background;
        border-radius: 10px;
    }
    @include mixins.not-phone {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
    }
}