@use "../css/variables.scss";
@use "../css/mixins.scss";

.main {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.for-tablet {
       height: 100vh;
       min-height: variables.$tablet-page-height;
    }

    @include mixins.for-desktop {
        height: 100vh;
        min-height: variables.$desktop-min-height;
    }
}

.main-default {
    background-color: #fff;
}

.content {
    position: relative;
    flex-grow: 1;
    width: 100%;
    max-width: 430px;
    
    @include mixins.not-phone {
        position: absolute;
        top: 50%;
        left: 50%;     
        transform: translate(-50%, -50%);
    }

    @include mixins.for-tablet {
        @include mixins.flex-row();
        
        background-color: #fff;
        max-width: variables.$tablet-page-width;
        height: variables.$tablet-page-height;
    }

    @include mixins.for-desktop {
        @include mixins.flex-row();

        background-color: #fff;
        max-width: variables.$desktop-page-width;
        height: variables.$desktop-page-height;
    }

    @include mixins.for-small-desktop {
        height: variables.$desktop-min-height;
    }
}