@use "../css/mixins.scss";
@use "../css/variables.scss";

.top {
    @include mixins.flex-column(variables.$spacing-single);
    background-color: #fff;
    opacity: 0.9;

    text-align: center;
    justify-content: center;
    width: 100%;

    @include mixins.for-phone {
        padding: variables.$spacing-double;
        box-sizing: border-box;
    }

    @include mixins.not-phone {
        justify-content: space-between;
        height: 50%;
    }
}

.bottom {
    @include mixins.vertical-bottom(variables.$spacing-single);
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonAnimationContainer {
    margin-top: 0;
    z-index: 0;
    width: 300px;
    height: auto;
}

.thankyou {
    font-size: variables.$font-size-large;
    font-weight: variables.$font-weight-semibold;
}

.moreButton {
    position: relative;
    background-color: variables.$color-intro-20;
    margin: 0 auto;
    gap: variables.$spacing-single;
    z-index: 100;
    
    @include mixins.not-phone {
        background-color: transparent;
        margin: -78px auto;
    }
}

.moreButton:hover {
    @include mixins.not-phone {
        background-color: variables.$highlight-color-hover;
    }
  }