@use "../css/mixins.scss";
@use "../css/variables.scss";

.container {
    @include mixins.flex-column();
}

.tocPanel {
}

.imageContainer {
    margin-top: variables.$spacing-double;

    img {
        width: 390px;
        height: auto;

        @include mixins.for-tablet {
            width: 320px;
        }
    }
}

.buttonContainer {
    margin-top: variables.$spacing-single;
}

.surveyTitle {
    font-size: variables.$font-size-medium;
    font-weight: variables.$font-weight-medium;
    color: variables.$main-dark;
    text-align: center;
    margin: variables.$spacing-double;
    white-space: pre-line;
}

.language {
    border: none;
    text-decoration: none;
    font-size: variables.$font-size-small-button;
    margin: variables.$spacing-double;
    color: variables.$color-intro-dark;

    @include mixins.for-tablet {
        margin: variables.$spacing-single;
    }
}

.version {
    font-size: variables.$font-size-small;
    color: #36c;
    margin: variables.$spacing-double;
    margin-top: 48px;
    text-align: center;

    @include mixins.not-phone {
        color: rgb(91, 119, 175);
        margin-top: 20px;
    }

    @include mixins.for-tablet {
        margin: variables.$spacing-single;
    }
}
