@use "../../css/mixins.scss";
@use "../../css/variables.scss";

.surveyContainer {
  @include mixins.for-phone {
    background-color: #fff;
    flex-grow: 1;
  }
  @include mixins.not-phone {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
  }
}

.questionLayout {
  display: flex;
  flex-direction: column;
  position: relative;
}

.question {
  font-size: variables.$font-size-large;
  padding: variables.$spacing-single;
  margin-bottom: variables.$spacing-double;
}

.error {
  position: absolute;
  top: calc(100% - 50%);
  left: 0;
  width: 100%;
  font-size: variables.$font-size-large;
  padding: variables.$spacing-single;
  text-align: center;
  color: variables.$error-color;

}

.session {
  font-size: variables.$font-size-small;
  padding: variables.$spacing-double 0;
}

.unsubmitted {
  color: #3c6;
  padding: variables.$spacing-single;
}
