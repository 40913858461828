@use "../css/mixins.scss";
@use "../css/variables.scss";

.consent-container {
    display: flex;
    flex-direction: column;

    .lottieContainer {
        width: 128px;
        height: 112px;

        @include mixins.not-phone {
            margin-top: variables.$spacing-double;
            width: 180px;
            height: 152px;
        }
    }

    .questionContainer {
        @include mixins.flex-column();
        
        justify-content: center;
        width: 100%;
        border-radius: 10px;
        box-sizing: border-box;
        margin-top: variables.$spacing-single;
        padding: variables.$spacing-single;

        @include mixins.for-phone {
            background-color: variables.$color-intro-background;
        }

        @include mixins.for-small-phone {
            display: none;
        }
    }

    .prompt {
        font-size: variables.$font-size-main;
        font-weight: variables.$font-weight-medium;
        padding: 0 variables.$spacing-single;

        @include mixins.for-desktop {
            padding: 0 48px;
        }
    }

    .scrollContainer {
        flex: 1 1 auto;
        overflow-y: scroll;
        margin-right: variables.$spacing-double;
        min-height: 150px;
    }

    .scrollArrow {
        position: absolute;
        right: 0;
        top: 100px;
        width: 15px;
        height: 100px;
    }

    .surveyInfo {
        padding: variables.$spacing-single variables.$spacing-double;
        white-space: pre-line;

        a {
            text-decoration: underline;
        }
    }

    .infoMessage {
        padding: variables.$spacing-half;
        font-size: variables.$font-size-normal;
        font-weight: variables.$font-weight-medium;
        text-align: center;

        @include mixins.for-phone {
            color: variables.$alert-color;
        }
    }

    .disabled, .disabled:hover {
        opacity: 0.25;
    }

    @include mixins.devices(phone) {
        min-height: 0;
        max-height: 100vh;
    }

    @include mixins.for-tablet {
        min-height: 0;
        max-height: variables.$tablet-page-height;
    }

    @include mixins.for-desktop {
        min-height: 0;
        max-height: variables.$desktop-page-height;
    }

    @include mixins.for-small-desktop {
        max-height: variables.$desktop-min-height;
    }

    .smallPhoneButtons {
        display: none;

        @include mixins.for-small-phone {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }

    .marginBottom {
        height: 80px;
        flex-shrink: 0;
    
        @include mixins.not-phone {
            height: variables.$spacing-single;
        }
    }
}

.sectionHeading {
    background-color: variables.$color-intro-main;
    border-radius: 10px;
    padding: variables.$spacing-half variables.$spacing-single;

    @include mixins.not-phone {
        background-color: variables.$color-intro-dark;
    }
}

.subtitle {
    font-size: variables.$font-size-medium;
    font-weight: variables.$font-weight-medium;
    text-align: center;
    color: #fff;
    font-size: 22px;
}