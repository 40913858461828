@use "../css/variables.scss";

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: variables.$spacing-single;
}

.circle {
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    border: none;
    border-radius: 8px;
    position: relative;
}

.check {
    position: absolute;
    left: 1px;
    top: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    padding: 0 2px;
}