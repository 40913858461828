@use "./variables.scss";

// example
@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
@mixin flex-row($gap: 0) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flex-inline($gap: 0) {
  display: inline-flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flex-column($gap: 0) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin vertical-top($margin: 0) {
  position: absolute;
  left: 0;
  top: $margin;
}

@mixin vertical-bottom($margin: 0) {
  position: absolute;
  left: 0;
  bottom: $margin;
}

// for media queries - handling different screen sizes

// $breakpoint is the variable that can have several values
@mixin devices ($breakpoint) {
  
  @if $breakpoint == smallphone {
    // iPhone SE/6/7/8 
    @media only screen and (max-width: 400px) and (max-height: 620px) {
      @content;
    }
  }

  @if $breakpoint == phone {    
    @media only screen and (max-width: 905px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: 906px) and (max-width: 1205px) {
      @content;
    }
  }

  @if $breakpoint == smalldesktop {
    @media only screen and (min-width: 1206px) and (max-height: 825px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (min-width: 1206px) {
      @content;
    }
  }
}

@mixin for-phone {
  @include devices(phone) { @content; }
}
@mixin for-small-phone {
  @include devices(smallphone) { @content; }
}
@mixin for-tablet {
  @include devices(tablet) { @content; }
}
@mixin for-desktop {
  @include devices(desktop) { @content; }
}
@mixin for-small-desktop {
  @include devices(smalldesktop) { @content; }
}
@mixin not-phone {
  @include devices(tablet) { @content; }
  @include devices(desktop) { @content; }
}
