
$font-size-title: 36px;
$font-size-subtitle: 32px;
$font-size-large: 28px;
$font-size-medium: 26px;
$font-size-main: 24px;
$font-size-more: 22px;
$font-size-normal: 18px;
$font-size-small: 10px;
$font-size-button: 24px;
$font-size-small-button: 20px;
$font-size-input: 32px;

$font-size-desktop: 22px;

$button-label-small: 18px;

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-button: 500;

$spacing-half: 4px;
$spacing-single: 8px;
$spacing-double: 16px;
$spacing-triple: 24px;

$phone-animation-width: 384px;
$phone-animation-height: 844px;
$tablet-animation-width: 450px;
$tablet-animation-height: 621px;
$desktop-animation-width: 540px;
$desktop-animation-height: 745px;

$tablet-page-marginx: 0px;
$tablet-page-marginy: 0px;
$tablet-page-width: 900px;
$tablet-halfpage-width: 450px;
$tablet-page-height: 620px;
$tablet-margin: 18px;
$tablet-spacing: 36px;

$desktop-page-marginx: 53px;
$desktop-page-marginy: 41px;
$desktop-min-marginy: $spacing-single;
$desktop-page-width: 1206px;
$desktop-halfpage-width: 603px;
$desktop-min-height: 760px;
$desktop-page-height: 826px;

$desktop-margin: 40px;
$spacing-desktop: 36px;

$main-dark: #3333a3;
$primary-text-dark: #000;
$hover-color: #A3A7FA;
$background-light-gray: #EDEFF0;
$alert-color: #008659;
$error-color: #C05600;
$highlight-color-20: #face00;
$highlight-bg-20: #face00e0; 
$highlight-color-hover: #face00; 
$text-link-color: #0076D6;

$color-intro-main: #656BD7; 
$color-intro-dark: #3333A3;
$color-intro-30: #A3A7FA; 
$color-intro-20: #CCCEFF; 
$color-intro-light: #F7F2FF; 
$color-intro-background: #F7F2FFe0; 

$color-participation-main: #008659;
$color-participation-dark: #0C4E29;
$color-participation-mid: #04C585;
$color-participation-20: #92D9BB;
$color-participation-10: #92D9BB;
$color-participation-light: #C9FBEB;
$color-participation-background: #DBF6EDe0;

$color-cohesion-main: #C05600;
$color-cohesion-dark: #5F3617;
$color-cohesion-mid: #FA9441;
$color-cohesion-20: #FFBC78;
$color-cohesion-10: #FCE2C5;
$color-cohesion-light: #FEF2E4;
$color-cohesion-background: #FEF2E4e0;

$color-wellbeing-main: #0076D6;
$color-wellbeing-dark: #0B4778;
$color-wellbeing-mid: #58B4FF;
$color-wellbeing-20: #A1D3FF; 
$color-wellbeing-10: #CFE8FF;
$color-wellbeing-light: #E8F5FF;
$color-wellbeing-background: #E8F5FFe0;

$color-perceptions-main: #D72D79;
$color-perceptions-dark: #731F44;
$color-perceptions-mid: #FF87B2;
$color-perceptions-20: #FFB4CF;
$color-perceptions-10: #FFDDEA;
$color-perceptions-light: #F9F0F2;
$color-perceptions-background: #F9F0F2e0;

$color-demographics-main: #9355dc;
$color-demographics-dark: #54278F;
$color-demographics-mid: #C39DEB;
$color-demographics-20: #D5BFFF;
$color-demographics-10: #EDE3FF;
$color-demographics-light: #F7F2FF;
$color-demographics-background: #F7F2FFe0;

$color-giveback-background: #008659;
$color-giveback-background-desktop: #3333A3;
