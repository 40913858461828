@use "../css/variables.scss";

.container {
    position: relative;
    box-sizing: border-box;
    padding: variables.$spacing-single;
    width: 100%;
    height: 48px;
}

.track {
    position: absolute;
    width: 100%;
    height: 3px;
    top: 50%;
    box-sizing: border-box;
    background-color: #000;
}

.thumb {
    position: absolute;
    top: calc(50% - 15px);
    width: 10px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 50%;
}

.labelContainer {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
}

.minimum {
    position: absolute;
    top: 8px;
    left: 0;
}

.maximum {
    position: absolute;
    top: 8px;
    right: 0;
}
