@use "../css/variables.scss";

.container {
    padding: variables.$spacing-double;
    box-sizing: border-box;
}

.errorAnimationContainer {
    width: 384px;
}

.error {
    color: variables.$error-color;
}

.bug {
    padding: variables.$spacing-double;
    text-decoration: underline;
    text-align: center;
}