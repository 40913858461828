@use "../css/mixins";
@use "../css/variables";

.creditsContainer {
    position: relative;
    text-align: center;
    height: 100%;

    @include mixins.for-tablet {
        overflow-y: auto;
    }

    @include mixins.for-desktop {
        margin-top: variables.$desktop-page-marginy;
        margin-left: variables.$desktop-page-marginx;
    }
}

.sectionContainer {
    margin: variables.$spacing-double;

    @include mixins.for-tablet {
        margin: variables.$spacing-single;
    }
}

.arrowContainer {
    position: absolute;
    left: 0;
    top: 24px;

    @include mixins.not-phone {
        top: 50%;
    }
}

.title {
  font-size: variables.$font-size-title;
  font-weight: variables.$font-weight-bold;
  color: variables.$main-dark;
  text-transform: uppercase;

  @include mixins.for-tablet {
    font-size: variables.$font-size-large;
  }
}

.description {
    margin-top: variables.$spacing-double;

    @include mixins.for-tablet {
        font-size: variables.$font-size-normal;
        margin-top: variables.$spacing-single;
    }
}

.name {
    font-size: variables.$font-size-large;
    font-weight: variables.$font-weight-medium;

    @include mixins.for-tablet {
        font-size: variables.$font-size-main;
    }
}